import React from 'react';
import styled from 'styled-components';
import { respondTo } from '../../util/breakpoints';
import * as colors from '../../util/colors';
import { DesktopOnly, H6Style, MobileOnly, HeaderH6 } from '../../util/typography';
import pinchIcon from '../../assets/utility/icons/pinch.svg';

const ChartContent = styled.div`
    border-radius: 6px;
    border: ${({type}) => type === 'keytruda' ? `1px solid ${colors.brandGreen}` : type === 'lenvima' ? `1px solid ${colors.mulberry}` : type === 'mechanism' ? `0px solid ${colors.pebble}`: `1px solid ${colors.pebble}`};
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${colors.white};
    img { 
        width: 100%;
    }
`;

const ChartTitle = styled.div`
    padding: 30px;
    color: ${({type}) => type === 'keytruda' ? colors.brandGreen : type === 'lenvima' ? colors.mulberry : colors.charcoal};
    ${H6Style};
    text-align: center;
    border-bottom: ${({type}) => type === 'keytruda' ? `1px solid ${colors.brandGreen}` : type === 'lenvima' ? `1px solid ${colors.mulberry}` : `1px solid ${colors.pebble}`};
`;

const ChartChildren = styled.div`
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    ${respondTo.md`
        padding: 20px;
        gap: 30px;
    `}
`;

const ChartWithNote = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const PinchContainer = styled.div`
    display: flex;
    gap: 10px;
    color: ${colors.greenDark};
    align-items: center;
`;

const Chart = ({children, className, imgSrc, imgAlt='', desktopImgSrc, noTitle, type, showNote = false}) => {
    return (
        <ChartWithNote className="chartNote">
        {showNote && <MobileOnly>
            <PinchContainer className="pinchContainer">
                <img src={pinchIcon} alt=''/>
                <HeaderH6>PINCH TO ZOOM & ENLARGE</HeaderH6>
            </PinchContainer>
        </MobileOnly>}
        <ChartContent className={className} type={type}>
            {!noTitle && <ChartTitle type={type}>
                {Array.isArray(children) && children[0] ? children[0] : children}
            </ChartTitle>}
            <ChartChildren className="chartChildren">
                <DesktopOnly>
                    <img src={desktopImgSrc} alt={imgAlt} />
                </DesktopOnly>
                <MobileOnly>
                    <img src={imgSrc} alt={imgAlt} />
                </MobileOnly>
                {noTitle ? children : Array.isArray(children) && children[1] && children.slice(1)}
            </ChartChildren>
        </ChartContent>
    </ChartWithNote>
)};
export default Chart;
